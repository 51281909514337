import React, { useState } from 'react';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import Button from '@mui/material/Button';
import { appStore } from '../store/store';

const ReactHealthActionButton = ({ websiteToken, patientSubdomain }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await appStore.sendPatientToReactHealth({ websiteToken, patientSubdomain })
    setIsLoading(false)
  }

  return (
    <Button
      sx={{ minWidth: '11rem', maxWidth: 'max-content', display: 'flex', alignItems: 'center', textTransform: 'none' }}
      variant='contained'
      onClick={handleClick}
      disabled={isLoading}
      startIcon={!isLoading && <SwitchAccessShortcutAddIcon />}
    >
      {isLoading ?
        <div className='spinner-border spinner-border-sm' role='status' /> :
        <span>
          Send to React-Connect
        </span>
      }
    </Button>
  )
}

export default ReactHealthActionButton