// FlagCheckbox.js
import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { collect } from 'react-recollect';
import ToolTip from './ToolTip';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';

const ReactHealthCheckBox = ({ store, checked, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  const submittedToRhAt = store?.selectedRow?.submitted_to_rh_at

  return submittedToRhAt ? (
    <Chip
      label={`Submitted to React-Connect on ${dayjs(submittedToRhAt).format('MM/DD/YYYY hh:mm a')}`}
      variant="filled"
    />
  ) : (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <span style={{ fontSize: '14px', color: 'var(--color-blue)' }}>
            Submit patient to React-Connect on complete
            <ToolTip
              id="reactHealthTooltip"
              content={
                <>
                  <div>Integrate with React Health on complete. </div>
                  <div>The attributes that will be submitted to React Health are as below:</div>
                  <ul>
                    <li>Patient ID</li>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Birthdate</li>
                    <li>Gender</li>
                    <li>City</li>
                    <li>State</li>
                  </ul>
                </>
              }
              type="info"
            />
          </span>
        }
      />
    </FormGroup>
  );
};

export default collect(ReactHealthCheckBox);